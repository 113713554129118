.graphs-container {
    display: grid;
    grid-template-columns: auto auto;
}

.graphs-container:nth-child(n) {
    animation: appear 1s linear 1s both;
}

.skills-graph {
    padding: 2px;
    margin: 2px;
    background: steelblue;
}

@media only screen and (max-width: 600px) {
    .graphs-container {
        display: flex;
        flex-direction: column;
    }
}

