.profile-bio {
    display: flex;
    flex-wrap: nowrap;
}

.profile-bio img {
    margin: 2rem 0.5rem 2rem 0.5rem;
    max-width: 300px;
    max-height: 300px;
    object-fit: cover;
}

.profile-quote {
    padding: 2rem;
    text-align: center;
}

.profile-quote q {
    font-style: italic;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content:space-around;
    animation: appear 1s linear 1s both;
}

.author {
    font-family: 'Princess Sofia', cursive;
    font-size: 1.5rem;
    color: rgb(9, 22, 22);
    text-decoration-line: none;
    font-weight: 900;
}

@media only screen and (max-width: 600px) {
    .profile-bio img {
        display: none;
    }
}