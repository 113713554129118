nav {
    height: 10vh;
    background-color: steelblue;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 99;
}

.logo-link {
    margin-left: 10vh;
    font-family: 'Princess Sofia', cursive;
    font-size: 2rem;
    color: rgb(9, 22, 22);
    text-decoration-line: none;
    font-weight: 900;
}

.nav-links {
    display: flex;
    list-style: none;
    width: 50%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
}

nav-links li {
    text-decoration: none;
}

.nav-link {
    color: white;
    text-decoration-line: none;
}

@media screen and (max-width: 768px) {
    .line {
        display: block;
        width: 30px;
        height: 3px;
        background: white;
        margin: 5px;
    }

    .hamburger {
        cursor: pointer;
        max-width: 30px;
        z-index: 2;
    }

    .nav-links {
        position: fixed;
        margin-top: 90vh;
        background: steelblue;
        height: 100vh;
        width: 100%;
        flex-direction:column;
        clip-path: circle(100px at 90% -10%);
        -webkit-clip-path: circle(100px at 90% -10%);
        transition: all 1s ease-out;
    }

    .nav-links.open {
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
    }

    .nav-link {
        display: block;
    }

    .nav-link {
        opacity: 0;
        font-size: 3em;
        transition: all 1s ease 0.5s;
    }

    .nav-link.fade {
        opacity: 1;
    }

    .logo-link {
        width: 80%;
    }
}