.card-container {
    width: 280px;
    background: rgba(235, 171, 52, 0.5);
    overflow: hidden;
    box-shadow: 0px 0px 15px 0px darken(steelblue, 5%);
    transition: .25s;
    animation: ease-in;
    margin: 10px;
}

.card-container:hover {
    transform: scale(0.9);
    box-shadow: 0px 0px 15px 0px;
}

.card-image img {
    overflow: hidden;
    height: 200px;
}

.card-content {
    margin: 1rem 0.5rem 1rem 1rem;
}

.card-title h3, .card-body p {
    margin: 0;
    padding: 0;
}

.card-title {
    margin-bottom: 0.5rem;
}

.card-body {
    min-height: 100px;
}

.card-button {
    display: flex;
    justify-content: center;
}

.card-button button {
    padding: 1rem;
    background-color: transparent;
    border: none;
    transition: 0.2s;
    margin-bottom: 0.5rem;
    border-radius: 3px;
}

.card-button button:hover {
    background: steelblue;
    transform: scale(1.1);
}

.card-link {
    text-transform: uppercase;
    text-decoration: none;
    color: darken(steelblue, 25%);
    font-weight: bold;
}

