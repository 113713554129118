* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page-main {
  background: rgb(153, 166, 176);
  display:flex;
  justify-content: space-around;
  width: 100%;
  min-height: 100vh;
}

.page-container {
  max-width: 1000px;
}

.page-info {
  margin-top: 10vh;
  margin-bottom: 1vh;
  padding: 1rem;
  background-color: lightsteelblue;
  border-left: 6px solid darkblue;
  width: 96%;
  margin-left: 2%;
  animation: slide-from-top 1s ease-in 0.5s both;
}

.footer-info {
  margin: 1vh;
  padding-left: 10vh;
  background-color: lightsteelblue;
  border-left: 6px solid darkblue;
  width: 96%;
  margin-left: 2%;
}

footer {
  padding-top: 1rem;
  min-height: 5rem;
  background-color: darkslategrey;
  display: flex;
  justify-content: center;
}

footer p {
  margin-top: 15px;
  color: aliceblue;
}

.icon-small {
  padding: 0 0 0 20px;
  margin-top: -5px;
  width: 80px;
}

.icon-smallest {
  width: 1.5rem;
  vertical-align: middle;
}

.top-separator {
  height: 1rem;
}

.heading-label {
  width: 80%;
  margin: 0.5rem 10% 0.5rem 10%;
  text-align: center;
  color: steelblue;
  background: aliceblue;
  border-radius: 1em;
  font-size: 1.5rem;
  box-shadow: 0px 5px 5px 0px;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.timeline-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.timeline-container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #FF9F55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-left {
  left: 0;
}

.timeline-right {
  left: 50%;
}

.timeline-left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

.timeline-right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.timeline-right::after {
  left: -16px;
}

.timeline-content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

@media screen and (max-width: 600px) {
  .timeline::after {
    left: 31px;
  }

  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25%;
  }

  .timeline-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  .timeline-left::after, .timeline-right::after {
    left: 15px;
  }

  .timeline-right {
    left: 0;
  }

}

@keyframes appear {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes slide-from-top {
  0% {transform: translateY(-600px);}
  80% {transform: translateY(20px);}
  100% {transform: translateY(0)}
}

/* @keyframes spin-around {
  0% {transform: rotateY(0deg);}
  25% {transform: rotateY(90deg);}
  50% {transform: rotateY(180deg);}
  75% {transform: rotateY(270deg);}
  100% {transform: rotateY(360deg);}
} */