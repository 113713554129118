.graphline-container {
    padding: 0.25rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 1%;
}

.graphline-bar {
    display: flex;
    flex-direction: row;
    border-radius: 1rem;
    box-shadow: 0px 0px 5px 0px;
}

.graphline-bar.red {
    background-color: crimson;
}

.graphline-bar.blue {
    background-color: rgb(41, 43, 173);
}

.graphline-bar.green {
    background-color: green;
}

.graphline-bar.purple {
    background-color: purple;
}

.graphline-bar.orange {
    background-color: darkgoldenrod;
}

.graphline-bar.pink {
    background-color: rgb(192, 72, 72);
}

.graphline-bar.gray {
    background-color: darkslategray;
}

.graphline-bar.lime {
    background-color: rgb(60, 148, 60);
}

.graphline-bar.violet {
    background-color: rgb(49, 38, 54);
}

.graphline-bar.brown {
    background-color: brown;
}

.graphline-bar.perc1 {
    width: 10%;
}

.graphline-bar.perc2 {
    width: calc(10% * 2);
}

.graphline-bar.perc3 {
    width: calc(10% * 3);
}

.graphline-bar.perc4 {
    width: calc(10% * 4);
}

.graphline-bar.perc5 {
    width: calc(10% * 5);
}

.graphline-bar.perc6 {
    width: calc(10% * 6);
}

.graphline-bar.perc7 {
    width: calc(10% * 7);
}

.graphline-bar.perc8 {
    width: calc(10% * 8);
}

.graphline-bar.perc9 {
    width: calc(10% * 9);
}

.graphline-bar.perc10 {
    width: calc(10% * 10);
}

.graphline-bar p {
    padding: 0.25rem;
    color: white;
}

.graphline-container h4 {
    padding: 0.25rem;
}

.graphline-container p {
    padding: 0.25rem;
}