.block-article-container {
    background-color: cadetblue;
    width: 100%;
    display: flex;
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem 0;
    box-shadow: 0 1px 0 1px cadetblue;
}


.block-article-image {
    margin: 1%;
    width: 18%;
}

.block-article-image img {
    border-radius: 5px;
    box-shadow: 0 1px 0 1px cadetblue;
    width: 100%;
}

.block-article-content {
    margin: 1%;
    width: 78%;
}

.block-article-links {
    display: flex;
    justify-content: space-between;
}