.experience-tab {
    display: flex;
}
.experience-tab-link {
    background-color: #555;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    font-size: 17px;
    width: 50%;
  }

.experience-tab-link:hover {
    background-color: #777;
}

.focus {
    background-color: steelblue;
}

.job-title-container {
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
}

.job-title-container h5 {
  margin-top: 2px;
}

.reason-leaving {
  color: darkslategrey;
}
